import FileInputLabel from '../RegistrationForm/CompanyInfo/FileInputLabel'
import { isTokenValid } from '../../../utils/functions'
import { updateCompany } from '../../../api/company'
import { Box, Button } from '@mui/material'
import React, { useState } from 'react'

export default function RegisterLicense({ alertSuccess, handleClose }) {
    const { user_id } = isTokenValid()
    const [license, setLicense] = useState([])
    const handleSubmit = () => {
        if (license?.length > 0) {
            const fd = new FormData()
            for (let l of license) { fd.append('license', l) }
            updateCompany(user_id, fd)
                .then(res => { alertSuccess(); handleClose() })
                .catch(e => { console.log("ERRs: ", e) })
        }
    }

    return (
        <Box sx={{ p: 4, width: '500px', textAlign: 'center' }}>
            <FileInputLabel files={license} setFiles={setLicense} />
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 1 }}>
                <Button onClick={ handleSubmit } size='small' variant='contained' sx={{ p: '4px', width: '150px' }}>Илгээх</Button>
            </Box>
        </Box>
    )
}
