import { addressValidationSchema } from '../../../../auth/RegisterAddress'
import GetLocation from '../../../../auth/RegistrationForm/GetLocation'
import { Box, Button, Dialog, Grid, Typography } from '@mui/material'
import { addBranch, updateBranch } from '../../../../../api/company'
import { emailYup, phoneYup } from '../../../../../utils/constants'
import CustomTextField from './CustomTextField'
import { useProfileCtx } from '../../..'
import { useFormik } from 'formik'
import { keys } from 'underscore'
import Manager from './Manager'
import Address from './Address'
import * as Yup from 'yup'
import React from 'react'

const validationSchema = Yup.object({
    name: Yup.string().required('Салбарын нэр оруулна уу!'),
    phone: Yup.string().matches(/^\d{8}$/, 'Утасны дугаар буруу байна!').required('Салбарын утасны дугаар оруулна уу!'),
    phone2: Yup.string().matches(/^\d{8}$/, 'Утасны дугаар буруу байна!').nullable(),
    address: addressValidationSchema.nullable(),
    lat: Yup.number()
        .required('Latitude is required')
        .min(-90, 'Latitude must be between -90 and 90')
        .max(90, 'Latitude must be between -90 and 90'),
    lng: Yup.number()
        .required('Longitude is required')
        .min(-180, 'Longitude must be between -180 and 180')
        .max(180, 'Longitude must be between -180 and 180'),
    address: Yup.string().nullable(),
    manager: Yup.object().when('$isMain', {
        is: false,  // condition based on ctx.parent
        then: Yup.object({
            name: Yup.string().required('Менежерийн нэр оруулна уу!').nullable(),
            ...emailYup,
            ...phoneYup,
        }).required('Менежерийн мэдээлэл оруулна уу!').nullable(),
        otherwise: Yup.object().nullable(), // or any other condition
    })
})

export default function AddBranch({ refetch, selected, open, handleClose }) {
    const { setMsg } = useProfileCtx()
    const isUpdate = Boolean(selected?.id)
    const isMain = isUpdate && selected?.isMain
    const { handleSubmit, getFieldProps, setFieldValue, setErrors, values, errors, resetForm } = useFormik({
        initialValues: {
            name: selected?.name||'',
            phone: selected?.phone||'',
            phone2: selected?.phone2||'',
            address: selected?.address?.ids||null,
            manager: selected?.manager||null,
            lat: selected?.lat||null,
            lng: selected?.lng||null,
            address2: selected?.address2||null,
        },
        onSubmit: (branchInfo) => {
            if (isUpdate) {
                updateBranch(branchInfo, selected.id)
                    .then(_ => { 
                        setMsg({ m: 'Амжилттай!', s: 'success' })
                        refetch()
                        handleClose()
                    })
                    .catch(e => {
                        console.log("ERR: ", e)
                        const d = e?.response?.data
                        if (d?.manager?.phone) { setErrors({ 'manager': { 'phone': d.manager.phone[0] } }) }
                        if (d?.manager?.name) { setErrors({ 'manager': { 'name': d.manager.name[0] } }) }
                    })
            } else {
                addBranch(branchInfo)
                    .then(res => {
                        setMsg({ m: 'Амжилттай!', s: 'success' })
                        refetch()
                        handleClose()
                    })
                    .catch(e => {
                        const d = e?.response?.data
                        if (d?.manager?.email) { setErrors({ 'manager': { 'email': d.manager.email[0] } }) }
                        if (d?.manager?.phone) { setErrors({ 'manager': { 'phone': d.manager.phone[0] } }) }
                        if (d?.manager?.name) { setErrors({ 'manager': { 'name': d.manager.name[0] } }) }
                    })
            }
        },
        validationSchema,
        validateOnChange: false
    })
    const { lat, lng, address2, ...rest } = values

    //console.log("V: ", lat, lng, address2)

    React.useEffect(() => {
        if (isUpdate) {
            if (values?.manager?.name === '' && values?.manager?.email === '' && values?.manager?.phone === '') {
                setFieldValue('manager', null)
            }
        }
    }, [values])

    React.useEffect(() => { resetForm() }, [])

    const getErrors = (name) => {
        const isErr = keys(errors)?.includes(name)// && keys(touched)?.includes(name)
        return {
            error: isErr,
            helperText: isErr ? errors[name] : ' '
        }
    }
    const handleAddressFieldValue = (field, value) => { setFieldValue(`address.${field}`, value) }

    const [isApiFailed, setIsApiFailed] = React.useState(false)
    const [isGettingAddress, setIsGettingAddress] = React.useState(false)        
    const handleAddressInfo = (value) => {
        setFieldValue('lat', value?.lat)
        setFieldValue('lng', value?.lng)
        setFieldValue('address2', value?.address2)
    }

    // err
    React.useEffect(() => {
        if (keys(errors)?.length === 1 || keys(errors)?.length === 2) {
            if (Boolean(errors?.address) === true && typeof errors.address === 'string') { setMsg({ m: errors?.address, s: 'warning' }) }
            else if (Boolean(errors?.manager) === true && typeof errors.manager === 'string') { setMsg({ m: errors?.manager, s: 'warning' }) }
        }
    }, [errors])

    return (
        <Dialog open={open} onClose={handleClose} maxWidth='600px'>
            <Box p={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ width: '100%' }}>
                    <Typography sx={{ mb: 1, color: 'primary.main' }}>Салбарын нэр, утас:</Typography>
                    <Grid container spacing={1}>
                        <Grid item sx={4}>
                            <CustomTextField
                                label='Салбарын нэр'
                                name='name'
                                {...getFieldProps('name')}
                                {...getErrors('name')}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CustomTextField
                                label='Салбарын утас'
                                name='phone'
                                {...getFieldProps('phone')}
                                {...getErrors('phone')}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CustomTextField
                                label='Салбарын утас2'
                                name='phone2'
                                {...getFieldProps('phone2')}
                                {...getErrors('phone2')}
                            />
                        </Grid>
                    </Grid>
                </Box>
                {
                    isApiFailed &&
                        <Address 
                            handleAddressFieldValue={handleAddressFieldValue}
                            values={values?.address}
                            errors={errors?.address}
                        />
                }
                <Box sx={{ width: '100%' }}>
                    <Typography sx={{ mb: 1, color: 'primary.main' }}>Салбарын хаяг:</Typography>
                    <GetLocation
                        addressInfo={{ lat, lng, address2 }}
                        setAddressInfo={handleAddressInfo}
                        setIsApiFailed={setIsApiFailed}
                        setIsGettingAddress={setIsGettingAddress}
                    />
                </Box>
                {
                    !isMain &&
                    <Box sx={{ width: '100%', mt: 2 }}>
                        <Typography sx={{ mb: 1, color: 'primary.main' }}>Салбарын эрхлэгч:</Typography>
                        <Manager 
                            getFieldProps={getFieldProps}
                            errors={errors?.manager}
                            setFieldValue={setFieldValue}
                            isUpdate={isUpdate}
                        />
                    </Box>
                }
                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Button
                        variant='contained'
                        size='small'
                        sx={{ my: 1, width: '200px' }}
                        onClick={handleSubmit}
                        disabled={isGettingAddress}
                    >Хадгалах</Button>
                </Box>
            </Box>
        </Dialog>
    )
}
