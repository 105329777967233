// map defaults
export const defaultZoom = 13
export const defaultCenter = { lat: 47.920761, lng: 106.917275 }
export const defaultBounds = {
    east: 106.95143561537243,
    north: 47.943648934976785,
    south: 47.897862934365456,
    west: 106.88311438462753
}
export const maxBounds = {
    east: 107.55264589487243,
    north: 48.121687606221315,
    south: 47.705783797188495,
    west: 106.384283528720
}

export const getStaffName = (names, id) => names.find(s => s.id === id)