import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { secondaryLight } from '../../../../../utils/constants'
import ShipmentRow from './ShipmentRow'
import { useShipCtx } from '..'

const cols = [ '№', '', 'Түгээгч', 'Эхэлсэн цаг', 'Дууссан цаг', 'Хүргэлтийн тоо', 'Явц', 'Зарлага', 'Цуцлах']
export default function CurrentShipment({ isDelman, ships }) {
    const { setMsg } = useShipCtx()

    const noShips = ships?.length === 0
    const noProgress = !ships?.find(s => s?.startTime)

    const goToShipmentProgress = () => {
        if (noShips) {
            setMsg({ m: 'Түгээлт байхгүй!', s: 'warning' })
            return
        } else if (noProgress) {
            setMsg({ m: 'Эхэлсэн түгээлт байхгүй!', s: 'warning' })
            return
        }
        window.open('/shipment_progress', '_blank')
    }

    return (
        <Box>
            {/* <Box sx={{ p: 1, textAlign: 'left' }}>
                <Button
                    size='small'
                    variant='contained'
                    sx={{ backgroundColor: 'primary.light' }}
                    onClick={goToShipmentProgress}
                >Түгээлтийн явц харах</Button>
            </Box> */}
            <TableContainer sx={{ p: 1, width: '1000px', boxShadow: 3  }}>
                <Table>
                    <TableHead>
                        <TableRow sx={{ '& > th': { color: 'white', textAlign: 'center', backgroundColor: secondaryLight, borderRight: '1px solid white', p: '4px' } }}>
                            {cols.map(c => <TableCell key={c}>{(isDelman && c === '№') ? '': c}</TableCell>)}
                        </TableRow>
                    </TableHead>
                        {
                            ships?.length > 0
                                ?   <TableBody>
                                        {
                                            ships?.map((s, idx) =>
                                                <ShipmentRow key={s.id} shipment={s} idx={idx} />
                                            )
                                        }
                                    </TableBody>
                                :   <TableBody>
                                        <TableRow sx={{ m: 2 }}>
                                            <TableCell colSpan={cols?.length} sx={{ textAlign: 'center' }}>Одоогоор түгээлт байхгүй!</TableCell>
                                        </TableRow>
                                    </TableBody>
                        }
                </Table>
            </TableContainer>
        </Box>
    )
}