import { removeTokens } from "../utils/functions"
import axios from "axios"

export const mainUrl = process.env.REACT_APP_MAIN_API
export const apiUrl = `${mainUrl}:${process.env.REACT_APP_API_PORT}`
export const baseUrl = `${apiUrl}/api/v1/`
export const macsInStockUrl = process.env.REACT_APP_MACS_INSTOCK_URL

const navToSignIn = () => { window.location.href = '/signin' }

const headers = {
	'Content-Type': 'application/json',
	'Accept': 'application/json'
}

export const Axios = axios.create({
	baseURL: baseUrl,
	//timeout: 8000,
	headers,
})

const AxiosWithToken = axios.create({
	baseURL: baseUrl,
	headers,
})

AxiosWithToken.interceptors.request.use(
	config => {
		const token = localStorage.getItem("access_token")
		if (token) {
			config.headers.Authorization = `Bearer ${token}`
			if (/\bproduct\//.test(config.url) || /\bcompany\//.test(config.url)) {
				config.headers['Content-Type'] = 'multipart/form-data'
			}
		} else { navToSignIn(); removeTokens(); }
		return config
	},
	error => Promise.reject(error)
)

AxiosWithToken.interceptors.response.use(
	response => response,
	async function (error) {
		const originalRequest = error.config
		if (typeof error.response === 'undefined') {
			alert( 'Уучлаарай, сервер хандах боломжгүй байна. Түр хүлээгээд дахин оролдоно уу!');
			return Promise.reject(error)
		}
		if ( error.response.status === 400) {
			const erd = error.response.data
			if (Array.isArray(erd) && erd.length > 0 && erd[0] === 'TOKEN NOT MATCH!') {
				navToSignIn(); removeTokens();
			}
		}
		if ( error.response?.data?.code === 'token_not_valid' && error.response.status === 401 &&	error.response.statusText === 'Unauthorized' ) {
			const refreshToken = localStorage.getItem('refresh_token')
			if (refreshToken) {
				const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]))
				const now = Math.ceil(Date.now() / 1000)
				if (tokenParts.exp > now) {
					try {
						const response = await Axios.post('auth/refresh/', { refresh: refreshToken })
						if (response.data.access) {
							localStorage.setItem('access_token', response.data.access)
							window.dispatchEvent(new CustomEvent('accessTokenChange', { detail: { access: response.data.access } }))
						}
						AxiosWithToken.defaults.headers['Authorization'] = 'Bearer ' + response.data.access_token
						originalRequest.headers['Authorization'] = 'Bearer ' + response.data.access_token
						return AxiosWithToken(originalRequest)
					} catch (error) { navToSignIn(); removeTokens(); }
				} else { navToSignIn(); removeTokens(); }
			} else { navToSignIn(); removeTokens(); }
		}
		return Promise.reject(error)
	}
)

export default AxiosWithToken