import PasswordField from '../../../../helpers/fields/PasswordField'
import { Box, Button, Dialog, Typography } from '@mui/material' 
import { getToast } from '../../../../auth/helpers/ToastAlert'
import { removeTokens } from '../../../../../utils/functions'
import { deleteUserAccount } from '../../../../../api/auth'
import { useNavigate } from 'react-router'
import { useProfileCtx } from '../../..'
import { useFormik } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'

const validationSchema = Yup.object({
    pwd: Yup.string()
            .required('Нууц үг оруулна уу!')
            .min(8, 'Нууц үг буруу!')
            .minLowercase(1, 'Нууц үг буруу!')
            .minUppercase(1, 'Нууц үг буруу!')
            .minNumbers(1, 'Нууц үг буруу!')
})

export default function DeleteUserAccount() {
    const { setMsg } = useProfileCtx()
    const navi = useNavigate()
    const [open, setOpen] = useState(false)
    const handleOpen = () => { setOpen(true) }
    const handleClose = () => { setOpen(false) }

    // success
    const [success, setSuccess] = useState(false)
    const handleSuccessClose = () => {
        removeTokens()
        handleClose()
        navi('/signin')
    }

    const { handleSubmit, getFieldProps, errors, touched } = useFormik({
        initialValues: { 'pwd': '' },
        onSubmit: ({ pwd }) => {
            deleteUserAccount({ pwd })
                .then(_ => { setSuccess(true) })
                .catch(_ => { setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' }) })
        },
        validationSchema,
        validateOnChange: false
    })

    const getErrors = (name) => {
        const isErr = Object.keys(errors)?.includes(name) && Object.keys(touched)?.includes(name)
        return {
            error: isErr,
            helperText: isErr ? errors[name] : ' '
        }
    }

    return (
        <Box sx={{ p: 2 }}>
            <Button onClick={handleOpen} variant='contained' sx={{ bgcolor: 'secondary.light' }}>Хэрэглэгчийн бүртгэл устгах</Button>
            {getToast(success, handleSuccessClose, 'Хэрэглэгчийн бүртгэл устгах хүсэлт амжилттай!')}
            <Dialog open={open} onClose={handleClose}>
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: 1, p: 3 }}>
                    <Typography textAlign='center'>Хэрэглэгчийн бүртгэл устгах</Typography>
                    <PasswordField
                        label='Нууц үг'
                        name='pwd'
                        {...getFieldProps('pwd')}
                        {...getErrors('pwd')}
                    />
                    <Button variant='contained' size='small' onClick={ handleSubmit }>
                        Устгах
                    </Button>
                </Box>
            </Dialog>
        </Box>
    )
}