import { apiUrl } from "../global/axiosBase"

// localStorage
export const getItm = (key) => window.localStorage.getItem(key)
export const setItm = (key, value) => window.localStorage.setItem(key, value)
export const removeItm = (key) => window.localStorage.removeItem(key)

// product image
export const sizes = [150, 300, 1000]
export const getImageUrl = (imgUrl, size) => {
    const parts = imgUrl?.split('.')
    const ext = parts?.pop()
    const imgName = parts ? parts.join() + `_${size}x${size}` + '.' + ext : null
    return imgName ? apiUrl + imgName : null
}

// number formatting
export const roundTo = (num, places) => {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
}

// map
export const mapOptions = {
    zoomControl: true,             // Show zoom control
    scrollwheel: true,             // Enable zooming with mouse wheel
    draggable: true,               // Enable panning by dragging
    streetViewControl: false,      // Disable Street View control
    fullscreenControl: false,      // Disable fullscreen button
    mapTypeControl: false,         // Disable map type control (e.g., Satellite view)
    scaleControl: false,           // Disable scale control (usually bottom right)
    rotateControl: false,          // Disable rotate control (for 45-degree imagery)
    clickableIcons: false,         // Disable the ability to click POI icons (e.g., businesses)
    controlSize: 24,               // Set the size of the controls that are displayed
}
export const maxBounds = {
    east: 120.24820753477137,
    north: 52.584035442100955,
    south: 41.39880233452084,
    west: 87.6083088439325
}