import { getShipmentDetail, getShipmentExpense } from '../../../../../../api/shipment'
import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { IconButton, TableCell, TableRow } from '@mui/material'
import { DurationCell, ExpenseCell, TimeCell } from './Cells'
import ShipDetail from './ShipmentDetail'
import { useAdminCtx } from '../../../..'
import { isEmpty } from 'underscore'
import React from 'react'

export default function ShipmentRow({ row, page, pageSize, i, delmanNames, isDelman, colLen, filters }) {
    const { alertWarning } = useAdminCtx()
    const progress = (row.progress > 0 && row.ordersCnt > 0) ? `${Math.round((row.progress / row.ordersCnt) * 100)} %` : '-'
    // shipment expense
    const [expenseDetail, setExpenseDetail] = React.useState(null)
    const getExpenseDetail = (shipmentId) => {
        getShipmentExpense(shipmentId)
            .then(res => { setExpenseDetail({ id: shipmentId, data: res.data }) })
            .catch(err => { console.log("expense ERR: ", err); alertWarning() })
    }

    // shipment detail
    const [detail, setDetail] = React.useState(null)
    const fetchDetail = () => {
        getShipmentDetail({ shipmentId: row?.id })
            .then(res => { setDetail(res?.data) })
            .catch(err => { console.log("DERR: ", err); alertWarning() })
    }
    const handleExpand = () => { isEmpty(detail) ? fetchDetail() : setDetail(null) }
    React.useEffect(() => { setDetail(null) }, [filters, page])

    return (
        <>
            <TableRow key={row.id} sx={{ '& > td': { p: '2px 6px', textAlign: 'center' } }}>
                <TableCell width='40px'>{page > 1 ? (page - 1) * pageSize + i + 1 : i + 1}</TableCell>
                <TableCell width='30px'>
                    <IconButton onClick={handleExpand} sx={{ p: '2px' }}>
                        {
                            isEmpty(detail) 
                                ?   <ArrowDownward sx={{ fontSize: '18px' }} />
                                :   <ArrowUpward sx={{ fontSize: '18px' }} />
                        }
                    </IconButton>
                </TableCell>
                {!isDelman && <TableCell>{delmanNames?.find(sn => sn.id === row.delman)?.['name']||'-'}</TableCell>}
                <TableCell>{row.createdOn?.split(' ')?.[0]}</TableCell>
                <TimeCell time={row.startTime} />
                <TimeCell time={row.endTime} />
                <DurationCell duration={row.duration} />
                <TableCell>{row.ordersCnt}</TableCell>
                <TableCell>{progress}</TableCell>
                <ExpenseCell
                    expenseSum={row.expense}
                    shipmentId={row.id}
                    getExpenseDetail={getExpenseDetail}
                    expenseDetail={expenseDetail}
                    setExpenseDetail={setExpenseDetail}
                />
            </TableRow>
            {!isEmpty(detail) && <ShipDetail detail={detail} colLen={colLen} />}
        </>
    )
}
