import { TableCell, TableRow } from '@mui/material';
import React, { useState } from 'react';
import { getToast } from '../../../../../auth/helpers/ToastAlert';
import { PROMO_TYPES } from '../../../../../constants'

export default function Row(props) {
    const { row, idx, page, pageSize } = props
    const [order, setOrder] = useState(row)
    const [err, setErr] = useState(null)
    const errOff = () => { setErr(null) }

    return (
        <>
            {getToast(Boolean(err), errOff, err, 'warning', 1500)}
            <TableRow sx={{ '&:hover': { backgroundColor: '#e4e4e4', cursor: 'pointer' }, '& > td': { textAlign: 'center', padding: '4px' } }}>
                <TableCell>{page <= 1 ? idx + 1 : (page - 1) * pageSize + idx + 1}</TableCell>
                <TableCell>{order?.name || '-'}</TableCell>
                <TableCell>{order?.promo_code || '-'}</TableCell>
                <TableCell>{PROMO_TYPES[order?.promo_type] || '-'}</TableCell>
                <TableCell>{order?.end_date || '-'}</TableCell>
            </TableRow>
        </>
    )
}
