import { Close } from "@mui/icons-material"
import { Box, Dialog, IconButton, TableCell, Typography, styled } from "@mui/material"
import React, { useState } from "react"
import DateRangePicker from "./DateRangePicker"

const StyledCloseIcon = styled(Close)(({ theme }) => ({ fontSize: '16px', color: theme.palette.secondary.main }))

export default function DatePicker({ filters, setFilters }) {
    const [open, setOpen] = useState(false)
    const handleOpen = () => { setOpen(true) }
    const handleClose = () => { setOpen(false) }
    const prevVal = filters?.find(f => ['end_date'].includes(f.k))?.fValue
    const handleClear = () => {
        console.log('shineod');
        setFilters(filters?.filter(f => !['end_date'].includes(f.k)))
    }

    const [hover, setHover] = useState(false)

    return (
        <TableCell key='createdOn' sx={{ p: '2px' }}>
            <Box
                sx={{
                    position: 'relative', width: '120px', textAlign: 'center',
                    border: 1, borderRadius: 0, p: '3px', pt: '2.5px'
                }}
                onMouseEnter={() => { setHover(true) }}
                onMouseLeave={() => { setHover(false) }}
            >
                <Typography
                    onClick={handleOpen}
                    sx={{
                        fontSize: '13px',
                        cursor: 'pointer',
                    }}
                >{prevVal || 'Сонгох'}</Typography>
                <IconButton onClick={handleClear} sx={{ p: 0, position: 'absolute', top: 0, right: 2, display: (prevVal && hover) ? 'block' : 'none' }}>
                    <StyledCloseIcon />
                </IconButton>
            </Box>
            <Dialog open={open} onClose={handleClose}>
                <DateRangePicker handleClose={handleClose} handleClear={handleClear} filters={filters} setFilters={setFilters} />
            </Dialog>
        </TableCell>
    )
}