import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { getPromoById, getPromotions } from '../../../../../api/promo'
import { paperStyle, primaryLight, scrollStyle } from '../../../../../utils/constants'
import { PROMO_TYPES } from '../../../../admin/tabs/Promotion/PromoTable/utils'
import TablePaginationFooter from '../../../../common/TablePaginationFooter'
import Filter from './Filter'
import Row from './Row'

const headCellStyle = { backgroundColor: 'secondary.light', color: 'white', borderRight: '1px solid white' }

export default function Promos({ promos, supplierId }) {
    //console.log("PROMOS: ", promos)
    // console.log(supplierId);
    const [selectedPromo, setSelectedPromo] = React.useState(null)
    // const columns = [
    //     { field: 'id', headerName: '№' },
    //     {
    //         field: 'name',
    //         headerName: 'Урамшууллын нэр',
    //     },
    //     {
    //         field: 'promo_code',
    //         headerName: 'Урамшууллын код',
    //     },
    //     {
    //         field: 'promo_type',
    //         headerName: 'Урамшууллын төрөл',
    //         valueGetter: (params) => {
    //             console.log(params.row.promo_type);
    //             return PROMO_TYPES[params.row.promo_type];
    //         },
    //     },
    //     {
    //         field: 'end_date',
    //         headerName: 'Дуусах огноо',
    //     }
    // ];
    const getPromoDetail = (promoId) => {
        console.log(promos);
        getPromoById(promoId)
            .then(res => { setSelectedPromo(res?.data) })
            .catch(err => { console.log("PROMO ERR: ", err) })
    }
    const handleRowClick = (params) => {
        console.log(params);
        getPromoDetail(params.id)
    };

    const rppOptions = [10, 25, 50]
    const [page, setPage] = useState(1)
    const handlePage = (page) => { setPage(page) }
    const [pageSize, setPageSize] = useState(10)
    const handlePageSize = (pageSize) => { setPageSize(pageSize) }

    const [filters, setFilters] = useState(null)
    const { data } = useQuery(['get_promos', filters, pageSize, page, supplierId], getPromotions)

    const { count, results } = data?.data || {}

    return (
        <Stack sx={{ mt: 3 }}>
            {/* <Box sx={{ p: 1, my: 1, display: 'flex', flexDirection: 'row', gap: 1, '& > div': { fontSize: '13px', color: 'primary.light' }, borderBottom: 1 }}>
                <Box sx={{ width: '20px' }}>№</Box>
                <Box sx={{ flexGrow: 1, ml: 2, mr: 5, maxWidth: '400px' }}>Урамшууллын нэр</Box>
                <Box sx={{ width: '250px', display: 'flex', alignItems: 'center' }}>Урамшууллын төрөл</Box>
                <Box sx={{ width: '100px' }}>Дуусах огноо</Box>
            </Box>
            {
                promos?.map((promo, idx) => (
                    <Box
                        key={promo.id}
                        sx={{
                            p: 1, boxShadow: 2, borderRadius: 1, my: 1,
                            borderColor: 'primary.main', border: 1, bgcolor: '#f7f7f5',
                            display: 'flex', flexDirection: 'row', gap: 1, transition: 'ease-in-out 0.1s',
                            '&:hover': { borderColor: 'primary.dark', cursor: 'pointer', boxShadow: 6 },
                            '& > div': { fontSize: '13px', color: 'primary.light' },
                        }}
                        onClick={() => getPromoDetail(promo.id)}
                    >
                        <Box sx={{ width: '20px' }}>{idx + 1}.</Box>
                        <Box>
                            <Tooltip title={promo?.has_gift ? 'Бэлэгтэй урамшуулал' : ''} placement='left'>
                                <Redeem sx={{ color: promo?.has_gift ? promoBtnColor : 'transparent' }} />
                            </Tooltip>
                        </Box>
                        <Box sx={{ flexGrow: 1, mr: 5, maxWidth: '400px', textShadow: '0.5px 0.5px #dfdce3' }}>{promo.name || '-'}</Box>
                        <Box sx={{ width: '250px', fontSize: '13px', textAlign: 'left' }}>{PROMO_TYPES[promo.promo_type] || '-'}</Box>
                        <Box sx={{ width: '100px' }}>{promo.end_date?.split(' ')?.[0] || '-'}</Box>
                    </Box>
                ))
            } */}
            {/* {promos !== undefined && promos.length > 0 ? (
                <Box sx={{ height: 700, width: '100%' }}>
                    <DataGrid
                        rows={promos}
                        columns={columns}
                        onRowClick={handleRowClick}
                        pageSizeOptions={[10, 25, 50]}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        componentsProps={{
                            pagination: {
                                labelRowsPerPage: 'Хуудсанд харуулах мөрийн тоо: '
                            }
                        }}
                    />
                </Box>
            ) : ''} */}

            <TableContainer sx={{ width: '100%', ...paperStyle, maxHeight: '650px', ...scrollStyle, '&::-webkit-scrollbar': { width: 8, height: 8 } }}>
                <Table aria-label="simple table" sx={{ fontSize: '12px' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ ...headCellStyle, width: '15px', textAlign: 'center', p: '3px' }}>№</TableCell>
                            {
                                ['Урамшууллын нэр', 'Урамшууллын код', 'Урамшууллын төрөл', 'Дуусах огноо']
                                    .map(f => <TableCell sx={{ ...headCellStyle, p: '2px', textAlign: 'center' }}>{f}</TableCell>)
                            }
                        </TableRow>
                    </TableHead>
                    <Filter filters={filters} setFilters={setFilters} />
                    <TableBody>
                        {
                            results?.length > 0
                                ? results?.map((row, idx) => <Row key={row?.id} row={row} idx={idx} page={page} pageSize={pageSize} />)
                                : <TableCell colSpan={12}>
                                    <Typography fontSize='16px' fontWeight='bold' textAlign='center' sx={{ color: primaryLight }}>Үр дүн олдсонгүй!</Typography>
                                </TableCell>
                        }
                    </TableBody>
                    {results?.length > 0
                        && <TablePaginationFooter
                            rppOptions={rppOptions}
                            count={count}
                            page={page}
                            handlePage={handlePage}
                            pageSize={pageSize}
                            handlePageSize={handlePageSize}
                        />}
                </Table>
            </TableContainer>
        </Stack>
    )
}
