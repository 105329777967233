import { toPrice } from '../../../../../../../../utils/functions'
import { getImageUrl } from '../../../../../../../utils'
import CardMedia from '@mui/material/CardMedia'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import React from 'react'

export default function Product ({ product, isPer = false }) {
    const { image, price, name } = product
    const imgUrl = getImageUrl(image, 150) || null
    const navigate = useNavigate()
    const goToDetail = () => { navigate(`/products/${product.id}`) }

    return (
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', borderRadius: 1, boxShadow: 5, p: 2, pb: 1 }}>
                <Box
                    sx={{
                        border: '1px solid', height: '180px', width: '150px',
                        position: 'relative', boxShadow: 1, 
                        borderRadius: 1, background: '#f7f7f5',
                        borderColor: 'secondary.main', 
                        '&:hover': { cursor: 'pointer', boxShadow: 6 }
                    }}
                >
                    <CardMedia
                        onClick={goToDetail}
                        component="img"
                        image={imgUrl || require(`./noimage.jpg`)}
                        alt="random"
                        sx={{
                            mb: 1, height: '60%', minWidth: '140px',
                            borderColor: 'neutral.main', opacity: '80%',
                            '&:hover': { cursor: 'pointer', opacity: '100%' }
                        }}
                    />
                    <Typography 
                        gutterBottom 
                        fontWeight={600}
                        sx={{
                            p: '1px 3px', ml: 1,
                            fontSize: '12.5px', p: 0,
                            '&:hover': { cursor: 'pointer' }
                        }}
                        onClick={ goToDetail }
                    >{name}</Typography>
                    <Typography
                        noWrap
                        sx={{
                            fontSize:'13px', textAlign: 'center', boxShadow: 1,
                            fontWeight: 600, position: 'absolute', bottom: 5, right: 5,
                            backgroundColor: 'secondary.light', color: 'white',
                            borderRadius: 1, p: '2px 8px',
                        }}
                    >{toPrice(price)}</Typography>
                </Box>
                {
                    (product?.qty && product?.qty > 1) && 
                    <Typography sx={{ color: 'primary.main', mt: 1 }}>{`${product?.qty} ш${isPer ? ' тутамд' : '-с дээш бол'}`}</Typography>
                }
            </Box>
    )
}