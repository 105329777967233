import AxiosWithToken from "../global/axiosBase"
import { getPageParams } from "./utils"
import { keys } from "underscore"

export const getPromoCols = () => AxiosWithToken.get('promo/columns/')

export const getPromos = ({ queryKey }) => {
    const [ _, filters, page, pageSize ] = queryKey
    let qParam = getPageParams(page, pageSize, 'promo/')
    for (let f of filters) {
        qParam += `&${f.k}=${f.v}`
    }
    return AxiosWithToken.get(qParam)
}

export const getPromo = (promoId) => AxiosWithToken.get(`promo/${promoId}/`)

export const createPromo = (data) => AxiosWithToken.post('promo/', data)

export const updatePromo = (promoId, data) => AxiosWithToken.patch(`promo/${promoId}/`, data)

export const deletePromo = (promoId) => AxiosWithToken.delete(`promo/${promoId}/`)

export const activatePromo = (promoId) => AxiosWithToken.patch(`promo/${promoId}/activate/`)
export const markPromo = (promoId) => AxiosWithToken.patch(`promo/${promoId}/mark/`)

// get customers for selection
export const getCustomers = ({ queryKey }) => {
    const [ _, name ] = queryKey
    let qParam = getPageParams(1, 10, 'partner/')
    if (name) { qParam += `&name=${name}` }
    //console.log("refetched: ",qParam)
    return AxiosWithToken.get(qParam)
}

// get products for selection
export const getSelectionProds = ({ queryKey }) => {
    const [ _, name ] = queryKey
    let qParam = getPageParams(1, 20, 'products/')
    if (name) { qParam += `&name=${name}` }
    return AxiosWithToken.get(qParam)
}

// target customer
export const getTargetGroups = () => AxiosWithToken.get('promo/target_group/')
export const getTargetGroup = (groupId) => AxiosWithToken.get(`promo/target_group/${groupId}/`)
export const createTargetGroup = (data) => AxiosWithToken.post('promo/target_group/', data)
export const updateTargetGroup = (tgId, data) => AxiosWithToken.patch(`promo/target_group/${tgId}/`, data)
export const deleteTargetGroup = (tgId) => AxiosWithToken.delete(`promo/target_group/${tgId}/`)

// for customer
export const getPromosBySupplier = ({ queryKey }) => {
    return AxiosWithToken.get('get_promos/')
}
export const getPromoById = (promoId) => {
    return AxiosWithToken.get(promoId ? `get_promos/${promoId}/` : 'get_promos/')
}
// get marked (or emphasized) promos
export const getMarkedPromos = () => AxiosWithToken.get('get_promos/marked/')

export const getMarkedPromosBySupplier = () => AxiosWithToken.get('marked_promos/')

export const noSeeMarkPromo = (promoId) => AxiosWithToken.patch(`marked_promos/${promoId}/`)

export const getPromotions = ({ queryKey }) => {
    const [_, filters, pageSize, page] = queryKey
    let qParam = 'get_promos/'
    if (keys(filters)?.length) {
        for (let fil of filters) {
            let fquery = fil?.k + fil?.o + fil?.v
            qParam += qParam?.endsWith('/') ? `?${fquery}` : qParam.endsWith('?') ? fquery : `&${fquery}`
        }
    }
    if (page) {
        let pgs = `page=${page}`
        qParam += qParam.endsWith('/') ? `?${pgs}` : `&${pgs}`
    }
    if (pageSize) {
        let pgs = `page_size=${pageSize}`
        qParam += qParam.endsWith('/') ? `?${pgs}` : `&${pgs}`
    }
    return AxiosWithToken.get(qParam)
}