import AllocationMapProvider from "../components/admin/tabs/Order/Orders/AllocationMap"
import DeliveryMap from "../components/admin/tabs/Shipment/DeliveryMap"
import PrivacyPolicy from "../components/home/footer/PrivacyPolicy"
import IsValidAdmin, { IsValid, IsValidR } from "./utils"
import { createBrowserRouter } from "react-router-dom"
import Confirmation from "../components/confirmation"
import { confirmRoutes } from "./confirmRoutes"
import SignIn from "../components/auth/SignIn"
import SignUp from "../components/auth/SignUp"
import ErrorPage from "./errorPage/ErrorPage"
import { adminRoutes } from "./adminRoutes"
import { homeRoutes } from "./homeRoutes"
import Admin from "../components/admin"
import Home from '../components/home'
import OutletBar from "../OutletBar"


export const mainRoute = createBrowserRouter([
    {
        path: '/',
        element: <IsValid><Home /></IsValid>,
        errorElement: <ErrorPage />,
        children: homeRoutes
    },
    {
        path: '/signin',
        element: <OutletBar><SignIn /></OutletBar>,
    },
    {
        path: '/signup',
        element: <OutletBar><SignUp /></OutletBar>,
    },
    {
        path: '/privacy_policy',
        element: <PrivacyPolicy />,
    },
    {
        path: '/admin',
        element: <IsValidAdmin><Admin /></IsValidAdmin>,
        children: adminRoutes
    },
    /* {
        path: '/shipment_progress',
        element: <IsValidAdmin><DeliveryMap /></IsValidAdmin>,
    },
    {
        path: '/to_shipment',
        element: <IsValidAdmin><AllocationMapProvider /></IsValidAdmin>,
    }, */
    {
        path: process.env.REACT_APP_CR,
        element: <IsValidR><Confirmation /></IsValidR>,
        children: confirmRoutes
    },
])