import { TableCell, TableRow, Tooltip } from '@mui/material'
import { FilterAlt } from '@mui/icons-material'
import DatePickerCell from './DatePickerCell'
import NumberCell from './NumberCell'
import { useShipCtx } from '../..'
import DropCell from './DropCell'
import React from 'react'

export default function FilterRow({ filters, setFilters, delmanNames, isDelman, trHeight }) {
    const choices = delmanNames?.reduce((acc, cur) => (acc[cur.id] = cur.name, acc), {})

    return (
        <TableRow sx={{ '& > th': { p: '2px 6px', position: 'sticky', top: trHeight } }}>
            <TableCell align='center'>
                <Tooltip title='Шүүж хайх мөр' placement='right'>
                    <FilterAlt sx={{ color: 'secondary.light', fontSize: '20px', m: 0 }} />
                </Tooltip>
            </TableCell>
            <TableCell />
            {!isDelman && <DropCell field='delman' choices={choices} filters={filters} setFilters={setFilters} />}
            <DatePickerCell filters={filters} setFilters={setFilters} />
            <TableCell colSpan={3} />
            <NumberCell field='ordersCnt' filters={filters} setFilters={setFilters} />
            <NumberCell field='progress' filters={filters} setFilters={setFilters} left={-178} />
            <NumberCell field='expense' filters={filters} setFilters={setFilters} left={-178} />
        </TableRow>
    )
}
