import { Box, Container, Typography } from '@mui/material'
import Footer from './Footer'
import Header from './Header'

export default function PrivacyPolicy() {
    return (
        <Box>
            <Header />
            <Container sx={{ mt: 13, pb: 3 }} maxWidth='md'>
                <Box sx={{ textAlign: 'right' }}>
                    <Typography variant='body2'>pharma.mn сайтын нууцлалын бодлого</Typography>
                    <Typography variant='body2'>Хүчин төгөлдөр болсон огноо: 2024.9.10</Typography>
                    <Typography variant='body2'>Аж ахуйн нэгжийн нэр: Инфо-Системс ХХК</Typography>
                </Box>
                <br />
                <Typography sx={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'center' }}>Нууцлалын бодлого</Typography>
                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'left' }}>1. Танилцуулга</Typography>
                <Typography sx={{ fontSize: '15px' }}>Энэхүү Нууцлалын бодлого нь Инфо-Системс ХХК-ийн удирддаг Pharma.mn програмд ​​хамаарна. Энэ нь бид хэрэглэгчийн хувийн болон нууц мэдээллийг хэрхэн цуглуулж, ашиглаж, хуваалцдаг талаар тайлбарладаг. Бидний зорилго бол манай үйлчилгээнд нэвтрэхдээ ил тод байдал, хэрэглэгчийн нууцлалыг хангах явдал юм. Хэрэв танд асуух зүйл байвал pharmo2023@gmail.com хаягаар бидэнтэй холбогдоно уу.</Typography>
                <br />
                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'left' }}>2. Бидний цуглуулдаг өгөгдөл</Typography>
                <Typography sx={{ fontSize: '14x' }}>Pharma.mn нь дараах төрлийн хувийн болон эмзэг хэрэглэгчийн мэдээллийг цуглуулдаг.
                </Typography>
                <ol>
                    <li>Хувийн мэдээлэл: Нэр, имэйл хаяг, утасны дугаар, хүргэх хаяг.</li>
                    <li>Дансны өгөгдөл: Имэйл хаяг, нууц үг (шифрлэгдсэн).</li>
                    <li>Захиалгын мэдээлэл: Захиалсан бүтээгдэхүүн, захиалгын түүх, сонголт.</li>
                    <li>Байршлын суурь өгөгдөл: Хэрэв та зөвшөөрөл өгвөл бид байршилд суурилсан үйлчилгээ, тухайлбал хүргэлтийг хянах, ойролцоох эмийн сангийн үйлчилгээг санал болгохын тулд байршлын мэдээллийг далд хэлбэрээр цуглуулдаг.</li>
                    <li>Зөвшөөрөлтэйгөөр бид нийцтэй байдал, аюулгүй байдал болон ажиллагааг сайжруулахын тулд таны төхөөрөмж дээр суулгасан програмуудын мэдээллийг цуглуулдаг.</li>
                </ol>
                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'left' }}>3.  Хэрэглэгчийн мэдээллийг хэрхэн ашиглах вэ?</Typography>
                <Typography>Pharma.mn цуглуулсан мэдээллийг дараах зорилгоор ашигладаг.</Typography>
                <ol>
                    <li>Захиалга боловсруулах, хүргэх.</li>
                    <li>Байршилд суурилсан үйлчилгээ үзүүлэх (жишээ нь, хүргэлтийг хянах).</li>
                    <li>Суулгасан програмын мэдээлэлд хандах замаар програмын нийцтэй байдал, аюулгүй байдлыг баталгаажуулна.</li>
                    <li>Таны захиалга, шинэчлэлт, саналын талаар тантай холбоо барина.</li>
                    <li>Хүргэлтийн үйлчилгээ үзүүлэгч эсвэл хуульд заасан нөхцөлөөс бусад тохиолдолд бид таны хувийн болон нууц мэдээллийг гуравдагч этгээдэд зарахгүй, хуваалцахгүй.</li>
                </ol>
                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'left' }}>4. Мэдээлэл хуваалцах</Typography>
                <Typography>Pharma.mn нь хэрэглэгчийн мэдээллийг дараах хүмүүстэй хуваалцаж болно.</Typography>
                <ul>
                    <li>Үйлчилгээ үзүүлэгчид: Итгэмжлэгдсэн түншүүд нь хүргэлтийн үйлчилгээнд дуртай бөгөөд захиалгыг биелүүлэхийн тулд таны өгөгдөлд хандах шаардлагатай байдаг.</li>
                    <li>Хуулийн шаардлага: Хууль, хууль эрх зүйн процесс эсвэл засгийн газрын эрх бүхий байгууллагаас шаардлагатай бол өгөгдлийг хуваалцаж болно.</li>
                </ul>
                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'left' }}>5. Мэдээлэл хадгалах, устгах</Typography>
                <Typography>Үйлчилгээ үзүүлэх, хууль ёсны үүргээ биелүүлэхэд шаардлагатай бол бид таны хувийн мэдээллийг хадгалдаг. Та дараах эрхтэй:</Typography>
                <ol>
                    <li>Өгөгдөлдөө хандахыг хүсэх.</li>
                    <li>Буруу өгөгдлийг залруулах хүсэлт гаргах.</li>
                    <li>Хуулиар хадгалахыг шаарддагаас бусад тохиолдолд шаардлагагүй болсон тохиолдолд өгөгдлийг устгах хүсэлт гаргана уу.</li>
                </ol>
                <Typography>Та эдгээр эрхээ pharmo2023@gmail.com, contact@infosystems.mn хаягаар бидэнтэй холбогдож ашиглаж болно.</Typography>
                <br />
                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'left' }}>6. Аюулгүй байдал</Typography>
                <Typography>Бид хувийн болон нууц мэдээллийг зөвшөөрөлгүй нэвтрэх, зөрчлөөс хамгаалахын тулд салбарын стандартын аюулгүй байдлын туршлагыг хэрэгжүүлдэг. Таны нэвтрэх мэдээлэл шифрлэгдсэн бөгөөд бид өгөгдлийг сервер дээрээ найдвартай хадгалах арга хэмжээ авдаг.</Typography>
                <br />
                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'left' }}>7. Нууцлалын бодлогын шинэчлэл</Typography>
                <Typography>Энэхүү Нууцлалын бодлогыг үе үе шинэчилж болно. Хэрэглэгчдэд томоохон өөрчлөлтүүдийн талаар имэйлээр эсвэл апп дотор мэдэгдэх болно. Шинэчилсэн удирдамжийг апп болон веб дотроос үзэх боломжтой.</Typography>
                <br />
                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'left' }}>8. Холбоо барих мэдээлэл</Typography>
                <Typography>Хэрэв танд энэхүү Нууцлалын бодлоготой холбоотой асуулт, санаа зовоосон зүйл байвал бидэнтэй холбогдоно уу:</Typography>
                <ul>
                    <li>Инфо-Системс ХХК</li>
                    <li>Имэйл: pharmo2023@gmail.com, contact@infosystems.mn</li>
                    <li>Утас: 70116399, 70126399, 91916549</li>
                </ul>
            </Container>
            <Footer />
        </Box>
    )
}
