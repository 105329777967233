import { getOrderFieldsInfo, getOrders, updateOrderFieldsInfo } from '../../../../../api/order'
import { Box, Button, Table, TableBody, TableContainer, Typography } from '@mui/material'
import TableCustomization from '../../../../common/TableCustomization'
import { useBcCtx } from '../../../../../context/BroadcastCtx'
import { scrollStyle } from '../../../../../utils/constants'
import TablePages from '../../../../common/TablePages'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import TableHeadRow from './TableHead'
import { useOrderListCtx } from '..'
import Row from './Row'

export default function Orders () {
    const { role, filters, page, pageSize, setPage, setPageSize, ordering } = useOrderListCtx()

    // orders
    const { data, isFetching, refetch } = useQuery(['getOrderData', filters, ordering, pageSize, page], getOrders)
    const { count, results: orders } = data?.data ? data.data : {}
    // order fields
    const { data: fields, refetch: refetchFieldsInfo } = useQuery('getOrderFieldsInfo', getOrderFieldsInfo)
    const fieldsInfo = fields?.data||[]
    const showns = fieldsInfo?.filter(f => f?.isShown)

    // Сонголттой талбарууд
    const fieldsWithChoices = fieldsInfo?.filter(f => f?.choices?.length)

    const [expandedRow, setExpandedRow] = useState(null)

    // refresh
    const { msg, setMsg } = useBcCtx()
    useEffect(() => {
        if (msg?.body?.type === 'OC') { refetch(); setMsg(null); console.log("OrderCreated!!!") }
    }, [msg, refetch, setMsg])

    if (!fieldsInfo?.length) return null

    // to allot
    const toAllot = () => { window.open('/to_shipment/', '_blank') }
    
    return (
        <TableContainer sx={{ height: '100%', ...scrollStyle, '&::-webkit-scrollbar': { width: 8, height: 8 } }}>
            <Table stickyHeader aria-label="simple table">
                <TableHeadRow showns={showns} orders={orders} />
                {
                    orders?.length > 0 &&
                        <TableBody>
                            {
                                orders.map(
                                    (row, idx) => 
                                        <Row
                                            key={`${row?.orderNo}`}
                                            initialRow={row} 
                                            role={role}
                                            idx={idx} 
                                            fieldsInfo={showns}
                                            fieldsWithChoices={fieldsWithChoices} 
                                            expandedRow={expandedRow}
                                            setExpandedRow={setExpandedRow}
                                            refetch={refetch}
                                            colsLength={showns?.length}
                                        />
                                    )
                            }
                        </TableBody>
                }
                <TablePages
                    count={count} 
                    page={page} 
                    setPage={setPage} 
                    pageSize={pageSize} 
                    setPageSize={setPageSize}
                    children={
                        <TableCustomization
                            fieldsInfo={fieldsInfo}
                            refetchFieldsInfo={refetchFieldsInfo}
                            updateFields={updateOrderFieldsInfo}
                        />
                    }
                    /* children2={
                        <Button
                            size='small'
                            variant='contained'
                            color='primary'
                            onClick={toAllot}
                        >Түгээлтэнд хуваарилах</Button>
                    } */
                />
            </Table>
            {
                orders?.length === 0 && !isFetching &&
                    <Box sx={{ display: 'flex', justifyContent: 'center', pt: 10 }}>
                        <Typography fontSize='15px' fontWeight='bold' textAlign='center'>
                            Үр дүн олдсонгүй
                        </Typography>
                    </Box>
            }
        </TableContainer>
    )
}