import DeleteUserAccount from './DeleteUserAccount'
import { Box } from '@mui/material'
import ChangePin from './ChangePin'
import ChangePwd from './PwdChange'
import React from 'react'

export default function Setting() {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <ChangePin />
            <ChangePwd />
            <DeleteUserAccount />
        </Box>
    )
}
