import { isTokenValid } from '../../../utils/functions'
import { updateCompany } from '../../../api/company'
import { Box, Button } from '@mui/material'
import AddressForm from './AddressForm'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import React from 'react'

export const addressValidationSchema = Yup.object({
    province: Yup.number().min(1).required('Аймаг/Хот сонгоно уу!').nullable(),
    district: Yup.number().min(1).required('Сум/Дүүрэг сонгоно уу!').nullable(),
    khoroo: Yup.number().min(1).required('Баг/Хороо сонгоно уу!').nullable(),
    detailed: Yup.string().nullable().min(1, 'Хаягийн дэлгэрэнгүй оруулна уу!').max(400, 'Хаягийн дэлгэрэнгүй 400 тэмдэгтээс урт байж болохгүй!').required('Хаягийн дэлгэрэнгүй оруулна уу!'),
})

export default function RegisterAddress({ alertSuccess, handleClose }) {
    const { user_id } = isTokenValid()
    const update = (data) => {
        const formData = new FormData()
        formData.append('address', JSON.stringify(data))
        updateCompany(user_id, formData)
            .then(_ => { handleClose(); alertSuccess() })
            .catch(e => { console.log("regAdr: ", e) })
    }

    const { handleSubmit, setFieldValue, values, errors, touched } = useFormik({
        initialValues: { province: null, district: null, khoroo: null, detailed: null },
        onSubmit: (address) => { update(address) },
        validationSchema: addressValidationSchema,
        validateOnChange: true
    })

    return (
        <Box sx={{ p: 4, width: '500px' }}>
            <AddressForm {...{ setFieldValue, values, touched, errors }} />
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1, mb: 2 }}>
                <Button onClick={ handleSubmit } size='small' variant='contained' sx={{ p: '4px', width: '150px' }}>Илгээх</Button>
            </Box>
        </Box>
    )
}
