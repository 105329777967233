import { ArrowDownward } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControlLabel, Grid, Tooltip, Typography, Zoom, styled, tooltipClasses } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { keys } from 'underscore';
import { pickSupplier } from '../../../../../api/auth';
import { getMarkedPromosBySupplier, noSeeMarkPromo } from '../../../../../api/promo';
import { useAuthCtx } from '../../../../../context/AuthCtx';
import { PHARMA_ROLES, scrollStyle } from '../../../../../utils/constants';
import { isTokenValid } from '../../../../../utils/functions';
import BundlePromo from '../../../../home/Main/Result/Promos/Promo/BundlePromo';
import ProductPromo from '../../../../home/Main/Result/Promos/Promo/ProductPromo';
import PurchasePromo from '../../../../home/Main/Result/Promos/Promo/PurchasePromo';
import DropMenu from './DropMenu';

const width = '180px'

export const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} TransitionComponent={Zoom} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'white',
        color: 'black',
        maxWidth: 'none',
        boxShadow: theme.shadows[2]
    }
}))
export default function Suppliers({ disabled = false }) {
    const { email, role, supplier } = isTokenValid()
    const isPharm = PHARMA_ROLES?.includes(role)
    const { supplierId, setSupplierId, suppliers } = useAuthCtx()
    const [open, setOpen] = React.useState(false);
    const [promos, setPromos] = React.useState([]);
    const [index, setIndex] = React.useState(0);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChange = (event) => {
        // setChecked(event.target.checked);
        if (event.target.checked) {
            noSeeMarkPromo(promos[index].id)
                .then(res => {
                    // if (res.status === 200) {
                    //     setToast(true);
                    // }
                })
                .catch(err => {
                    console.log(err);
                });
        }
    };
    const handleClick = async (id) => {
        try {
            if (email && isPharm) {
                pickSupplier(id).then(async res => {
                    setSupplierId(res?.data?.id)
                    if (res?.data?.access_token && res?.data?.refresh_token) {
                        window.localStorage.setItem('access_token', res?.data?.access_token)
                        window.localStorage.setItem('refresh_token', res?.data?.refresh_token)
                    }

                    setIndex(0);
                    const resPromo = await getMarkedPromosBySupplier();
                    setPromos(resPromo.data);
                    console.log(resPromo.data);
                    if (resPromo.data.length > 0) {
                        handleOpen();
                    }

                }).catch(e => { console.log("Pick supplier err: ", e) })
            }
        } catch (error) {
            console.log(error);
        }

    }
    const getSup = () => {
        if (supplierId) {
            return suppliers?.[supplierId]
        } else {
            if (supplier) {
                const isInc = keys(suppliers).map(k => parseInt(k)).includes(supplier)
                return isInc ? suppliers?.[supplier] : 'Сонгох'
            }
            return 'Сонгох'
        }
    }
    const handleNext = () => {
        let dd = index + 1;
        if (dd <= promos.length - 1) {
            setIndex(dd);
        }
    }
    const handlePrevious = () => {
        let dd = index - 1;
        if (dd >= 0) {
            setIndex(dd);
        }
    }

    return (
        <Box>
            <DropMenu
                title={
                    <Button
                        sx={{
                            width, color: 'white',
                            borderRadius: 0, p: 0,
                            backgroundColor: 'secondary.light',
                            '&:hover': { backgroundColor: 'secondary.light' },
                        }}
                        size='small'
                    >
                        <Typography noWrap sx={{ fontSize: '14px' }}>{getSup()}</Typography>
                        {isPharm ? <ArrowDownward color='white' sx={{ fontSize: '15px', ml: 1 }} /> : null}
                    </Button>
                }
                detail={
                    isPharm
                        ? <Box
                            sx={{
                                display: 'flex', flexDirection: 'column', p: 0, m: 0, backgroundColor: 'white',
                                maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden', boxShadow: 3, border: 1,
                                ...scrollStyle, '&::-webkit-scrollbar': { width: 5 }, visibility: disabled ? 'hidden' : 'visible'
                            }}
                        >
                            {keys(suppliers).map(k => ( // .filter(s => s !== supplierId)
                                <Button
                                    key={`${k}`}
                                    onClick={() => { handleClick(k) }}
                                    sx={{
                                        width,
                                        borderBottom: '1px solid transparent',
                                        borderRadius: 0,
                                        '&:hover': { borderBottom: '1px solid #ad5555' },
                                        display: 'flex', justifyContent: 'left',
                                        pl: 1
                                    }}
                                >
                                    <Typography noWrap fontSize='14px'>{suppliers[k]}</Typography>
                                </Button>
                            ))}
                        </Box>
                        : null
                }
            />
            <Dialog open={open} onClose={handleClose} aria-describedby="alert-dialog-slide-description" maxWidth='md' fullWidth>
                <DialogTitle>{"Онцлох урамшуулал " + (index + 1)}</DialogTitle>
                <DialogContent sx={{ p: 2, ...scrollStyle, '&::-webkit-scrollbar': { width: 8 } }}>

                    {promos.length > 0 && promos[index].promo_type === 1 && <BundlePromo promo={promos[index]} />}
                    {promos.length > 0 && promos[index].promo_type === 2 && <PurchasePromo promo={promos[index]} />}
                    {promos.length > 0 && promos[index].promo_type === 3 && <ProductPromo promo={promos[index]} isProduct={true} />}

                </DialogContent>
                <DialogActions>
                    <Grid container sx={{ marginLeft: 2, marginRight: 2 }} direction='row' justifyContent='space-between' alignItems='center'>
                        <Grid item>
                            <FormControlLabel
                                label="Дахин харахгүй"
                                control={
                                    <Checkbox
                                        // checked={checked}
                                        onChange={handleChange}
                                    />
                                }
                            />
                        </Grid>
                        {promos.length > 1 ? (
                            <Grid item>
                                <Button onClick={handlePrevious}>Өмнөх</Button>
                                <Button onClick={handleNext}>Дараах</Button>
                            </Grid>
                        ) : ''}
                    </Grid>

                </DialogActions>
            </Dialog>
            {/* {promos.length > 0 ? <Snackbar open={toast} autoHideDuration={3000} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity="warning" variant="filled" sx={{ width: '100%' }}>
                    {promos[index].name} урамшууллыг дахин харахгүй.
                </Alert>
            </Snackbar> : ''} */}
        </Box>

    )
}
