import { FilterAlt } from '@mui/icons-material'
import { TableCell, TableRow, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { primaryLight, secondaryLight } from '../../../../../../utils/constants'
import { PROMO_TYPES } from '../../../../../constants'
import DropCell from '../DropCell'
import DatePicker from '../DatePicker'

export default function Filter({ filters, setFilters, hhRef }) {
    const [top, setTop] = useState(hhRef?.current?.getBoundingClientRect()?.height)
    useEffect(() => {
        const newTop = hhRef?.current?.getBoundingClientRect()?.height
        if (newTop) { setTop(newTop) }
    }, [hhRef])

    return (
        <TableRow
            sx={{
                '& > td': {
                    m: 0, p: 0, borderBottom: '1px solid gray', zIndex: 1050, top,
                    borderBottomColor: primaryLight, position: 'sticky', backgroundColor: 'white',
                    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)'
                }
            }}
        >
            <TableCell align='center'>
                <Tooltip title='Захиалгын шүүлтүүр' placement='right'><FilterAlt sx={{ color: secondaryLight }} /></Tooltip>
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <DropCell field={'promo_type'} choices={PROMO_TYPES} filters={filters} setFilters={setFilters} />
            <DatePicker filters={filters} setFilters={setFilters} />
        </TableRow>
    )
}
