import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { getToast } from '../../../auth/helpers/ToastAlert' 
import { getTodayShipment } from '../../../../api/shipment'
import { isTokenValid } from '../../../../utils/functions'
import { getDelmanNames } from '../../../../api/staff'
import { Box, Tab, Tabs } from '@mui/material'
import ShipmentHistory from './History'
import CurrentShipment from './Current'
import { useQuery } from 'react-query'
import '../../../../style.css'

const ShipCtx = createContext()
export const useShipCtx = () => useContext(ShipCtx)

const Shipment = () => {
    const { role } = isTokenValid()
    const isDelman = role === 'D'
    const [tab, setTab] = useState(0)
    const handleChange = (e, v) => { setTab(v) }

    const [staffs, setStaffs] = useState([])
    const delmanNames = staffs?.map(s => ({ id: s.id, name: s.name }))
    useEffect(() => {
        getDelmanNames()
            .then(res => { if (res?.data) { setStaffs(res.data) } })
            .catch(err => { console.log("staffs ERR: ", err) })
    }, [])

    // msg
    const [msg, setMsg] = useState(null)
	const msgOff = () => { setMsg(null) }
    const alertWarning = () => { setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' }) }
    const alertSuccess = () => { setMsg({ m: 'Амжилттай!', s: 'success' }) }

    // today shipments
    const { data, refetch: refetchShipments } = useQuery('get_today_shipment', getTodayShipment, { onError: alertWarning })
    const ships = data?.data?.results||[]
    
    const tabRef = useRef()
    const tabHeight = tabRef?.current?.getBoundingClientRect()?.height||0

    return (
        <ShipCtx.Provider value={{ isDelman, delmanNames, msg, setMsg, alertSuccess, alertWarning, refetchShipments }}>
            {getToast(Boolean(msg?.m), msgOff, msg?.m, msg?.s, 2000)}
            <Tabs value={tab} onChange={handleChange} aria-label='tabs' ref={tabRef}>
                <Tab label='Өнөөдрийн түгээлт' value={0} />
                <Tab label='Түгээлтийн түүх' value={1} />
            </Tabs>
            <Box sx={{ height: `calc(100% - ${tabHeight}px)` }}>
                { tab === 0 && <CurrentShipment isDelman={isDelman} ships={ships} />}
                { tab === 1 && <ShipmentHistory />}
            </Box>
        </ShipCtx.Provider>
    )
}

export default Shipment
