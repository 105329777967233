import { Collapse, IconButton, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useBcCtx } from '../../../../../../../context/BroadcastCtx'
import { isTokenValid } from '../../../../../../../utils/functions'
import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import ShipItems from './ShipItems'

const cols = ['№', '', 'Захиалгын дугаар', 'Захиалагч', 'Төлөв', 'Хүргэсэн цаг', 'Тэмдэглэл']

export default function ShipDetail({ detail, updMsg, setUpdMsg, fetchShip, isShipmentEnded }) {
    //console.log("detail: ", detail)
    const { role } = isTokenValid()
    const [items, setItems] = useState(detail||[])

    const [expanded, setExpanded] = useState(null)
    const handleExpand = (orderNo) => {
        if (expanded === orderNo) { setExpanded(null) }
        else { setExpanded(orderNo) }
    }
    // real time update
    const { msg, setMsg } = useBcCtx()
    useEffect(() => {
        if (msg?.ship_item) {
            fetchShip()
            setItems(p => ([...p.map(i => i.id === msg.ship_item.id ? msg.ship_item : i)]))
            setUpdMsg({s: 'success', m: `${msg.ship_item.orderNo} дугаартай захиалга хүргэгдлээ!`, id: msg.ship_item.id})
            setMsg(null)
        }
    }, [msg])

    return (
        <TableRow sx={{ '& > td': { p: '3px', pl: 3 } }}>
            <TableCell colSpan={6}>
                <Collapse in={true} timeout='auto' unmountOnExit>
                        <TableHead>
                            <TableRow sx={{ '& > th': { borderBottomWidth: '2px', fontWeight: 'bold', p: '2px 6px', textAlign: 'center' } }}>
                                {cols.map(c => <TableCell>{c}</TableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items?.map((item, idx) => {
                                const ig = item.givenOn
                                const parts = ig ? ig.split(' ') : null
                                const t = parts?.length > 1 ? parts[1] : null
                                const tps = t ? t.split(':') : null
                                const ts = tps?.length > 1 ? `${tps[0]} цаг ${tps[1]} минут` : null
                                return <>
                                        <TableRow sx={{ '& > td': { p: '2px 10px', textAlign: 'center' } }}>
                                            <TableCell>{idx+1}</TableCell>
                                            <TableCell>
                                                {
                                                    role === 'D' &&
                                                        <IconButton onClick={() => handleExpand(item.orderNo)} sx={{ p: 0 }}>
                                                            {
                                                                expanded === item.orderNo
                                                                    ?   <ArrowUpward sx={{ fontSize: '18px' }}></ArrowUpward>
                                                                    :   <ArrowDownward sx={{ fontSize: '18px' }}></ArrowDownward>
                                                            }
                                                        </IconButton>
                                                }
                                            </TableCell>
                                            <TableCell>{item.orderNo}</TableCell>
                                            <TableCell>{`${item.user} (${item.branch||''})`}</TableCell>
                                            <TableCell 
                                                sx={{ 
                                                    backgroundColor: updMsg?.id === item.id ? '#307e34' : 'initial',
                                                    color: updMsg?.id === item.id ? 'white' : 'initial',
                                                }}
                                            >
                                                {item.process === 'A' ? 'Хүргэгдсэн' : '-'}
                                            </TableCell>
                                            <TableCell 
                                                sx={{ 
                                                    backgroundColor: updMsg?.id === item.id ? '#307e34' : 'initial',
                                                    color: updMsg?.id === item.id ? 'white' : 'initial',                                                    
                                                }}
                                            >
                                                {ts ? ts : '-'}
                                            </TableCell>
                                            <TableCell>{item.note || '-'}</TableCell> 
                                        </TableRow>
                                        {expanded === item.orderNo && <ShipItems inItems={item.items} disabled={item.isGiven||isShipmentEnded} setItems={setItems} cl={cols.length} />}
                                    </> 
                            })}
                        </TableBody>
                </Collapse>
            </TableCell>
        </TableRow>
    )
}
