import { Box, Button } from '@mui/material'
import { format } from 'date-fns'
import { mn } from 'date-fns/locale'
import React, { useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
registerLocale('mn', mn)

export default function DateRangePicker({ handleClose, handleClear, filters, setFilters }) {
    const [end, setEnd] = useState(filters?.find(f => f.k === 'end_date')?.dateObj || null)

    const handleEndChange = (date) => { setEnd(date) }

    const handleFilter = () => {
        if (end) {
            const dateFs = []
            if (end) { dateFs.push({ k: 'end_date', v: format(end, 'yyyy-MM-dd'), o: '=', dateObj: end }) }
            setFilters(filters?.length > 0 ? [...filters.filter(f => !['end_date'].includes(f.k)), ...dateFs] : dateFs)
        }
        handleClose()
    }

    // const handleClear = () => {
    //     let dates = filters
    //     const ll = dates.findIndex(f => String(f.k) === 'end_date')
    //     if (ll !== -1) {
    //         dates.splice(ll, 1)            
    //     }
    //     setFilters(dates)
    //     handleClose()
    // }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', p: 3, gap: 2, height: '330px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <DatePicker
                        locale='mn'
                        selected={end}
                        onChange={handleEndChange}
                        selectsEnd
                        endDate={end}
                        dateFormat="yyyy/MM/dd"
                        placeholderText="Дуусах огноо"
                        className="date-picker-input"
                        showIcon
                        isClearable
                    />
                </Box>
            </Box>
            <Button size='small' variant='contained' onClick={handleFilter}>Шүүх</Button>
            <Button size='small' variant='contained' onClick={handleClear}>Цэвэрлэх</Button>
        </Box>
    )
}