import { AppBar, Container, Toolbar } from '@mui/material'
import LeftSection from '../../../../navbar/LeftSection'
import NavTop from '../../../top'

export default function Header() {
    return (
        <AppBar position='fixed'>
            <NavTop />
            <Toolbar>
                <Container 
                    maxWidth='xl' 
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center'
                    }}
                >
                    <LeftSection isAuthRoute={false} />
                </Container>
            </Toolbar>
        </AppBar>
    )
}