import { getShipment, getShipmentDetail } from '../../../../../../api/shipment'
import { getToast } from '../../../../../auth/helpers/ToastAlert'
import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { IconButton, TableCell, TableRow } from '@mui/material'
import ExpenseCell from './Cells/ExpenseCell'
import { getStaffName } from '../../utils'
import CancelShip from './Cells/CancelShip'
import React, { useState } from 'react'
import ShipDetail from './ShipDetail'
import { useShipCtx } from '../..'

export default function ShipmentRow({ shipment, idx }) {
    const { alertWarning } = useShipCtx()
    const [ship, setShip] = useState(shipment)
    //console.log("sh: ", ship)
    const fetchShip = () => {
        getShipment(ship.id)
            .then(res => { setShip(res?.data); console.log("RE: ", res?.data) })
            .catch(err => { alertWarning(); console.log("ERR: ", err) })
    }
    const { delmanNames, isDelman } = useShipCtx()
    const [detail, setDetail] = useState(isDelman ? ship.inItems : [])
    //console.log("DT: ", detail)
    const isFetched = detail?.length !== 0
    const fetchDetail = () => {
        getShipmentDetail({ shipmentId: ship?.id })
            .then(res => { setDetail(res?.data) })
            .catch(err => { alertWarning(); console.log("DERR: ", err) })
    }
    const handleExpand = () => { isFetched ? setDetail([]) : fetchDetail() }

    // toast
    const [msg, setMsg] = useState(null)
    const msgOff = () => { setMsg(null) }

    return (
        <>
            {getToast(Boolean(msg), msgOff, msg?.m, msg?.s, 2500)}
            <TableRow sx={{ '&:hover': { backgroundColor: '#f1f1f1' }, '& > td': { p: '3px', textAlign: 'center' } }}>
                <TableCell width='30px'>{!isDelman ? idx+1 : ''}</TableCell>
                <TableCell width='30px'>
                    {
                        !isDelman &&
                            <IconButton onClick={handleExpand} sx={{ p: '2px' }}>
                                {!isFetched ? <ArrowDownward sx={{ fontSize: '18px' }} /> : <ArrowUpward sx={{ fontSize: '18px' }} />}
                            </IconButton>
                    }
                </TableCell>
                {<TableCell>{getStaffName(delmanNames, ship?.delman)?.name||'Түгээгч'}</TableCell>}
                <TableCell width='160px'>{ship.startTime||'-'}</TableCell>
                <TableCell width='160px'>{ship.endTime||'-'}</TableCell>
                <TableCell>{ship.ordersCnt||'-'}</TableCell>
                <TableCell>{(ship.progress > 0 && ship.ordersCnt > 0) ? `${Math.round((ship.progress / ship.ordersCnt) * 100)} %` : '-'}</TableCell>
                <ExpenseCell expense={ship.expense} />
                <CancelShip ship={ship} />
            </TableRow>
            {isFetched && <ShipDetail detail={detail} updMsg={msg} setUpdMsg={setMsg} fetchShip={fetchShip} isShipmentEnded={Boolean(shipment?.endTime)} />}
        </>
    )
}
